<template>
  <div style="display: flex; align-items: center;">
    <!-- Icon on the left -->
    <v-icon @click="openFilePicker()">{{ iconAttachment }}</v-icon>

    <!-- Input text box on the right -->
    <v-text-field
      class="ml-2 mt-2"
      dense
      v-model="selectedFilePath"
      label="Select a File"
      readonly
      @click="openFilePicker()"
    ></v-text-field>
  </div>
</template>

<script>
import { iconAttachment } from "@/design/icon/iconConst";
import { openFile } from "@/services/dom/fileSystemAccessService";
import { extensionName } from "@/model/record/fileModel";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "OpenFilePickerUpload",
  data() {
    return {
      selectedFilePath: "",
      iconAttachment: iconAttachment
    };
  },

  methods: {
    async openFilePicker() {
      try {
        const file = await openFile(extensionName.pdf, true);
        if (file) {
          console.log(`${this.$options.name}: File:`, file);
          // Set the selected file path in the component's data
          this.selectedFilePath = file.name;
          this.$emit(eventNames.onFileChanged, file);
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }
};
</script>

<style scoped></style>
