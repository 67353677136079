/**
 * create Script Element
 * @param id
 * @param src
 * @param integrity
 */

const createScriptElement = (id, src, integrity) => {
  let el = document.getElementById(id);
  if (el) return;

  el = document.createElement("script");
  el.setAttribute("id", id);
  el.setAttribute("src", src);

  if (integrity) {
    el.setAttribute("integrity", integrity);
    el.setAttribute("crossorigin", "anonymous");
  }

  document.head.appendChild(el);
};

/**
 * create CSS Element
 * @param id
 * @param href
 */
const createCSSElement = (id, href) => {
  let el = document.getElementById(id);
  if (el) return;

  el = document.createElement("link");
  el.setAttribute("id", id);
  el.setAttribute("href", href);
  el.setAttribute("rel", "stylesheet");
  el.setAttribute("type", "text/css");

  document.head.appendChild(el);
};

/**
 * Create Download File Anchor
 * @param {Blob} blob
 * @param {String|string} name
 * @param {String|string} ext
 */
function createDownloadFileAnchor(blob, name, ext) {
  const el = document.createElement("a");

  try {
    // creates a DOMString containing a URL representing the object given in the parameter
    el.href = window.URL.createObjectURL(blob);

    // DOMString indicating that the linked resource is intended to be downloaded
    // rather than displayed in the browser
    el.download = name + "." + ext;
    el.style.display = "none";

    // append download File anchor
    document.body.appendChild(el);

    // simulates a mouse click on an element.
    el.click();
  } finally {
    document.body.removeChild(el);
  }
}
/*

/!**
 * Get File Extension from Blob
 * @param blobType
 * @return {string|*}
 *!/
function getBlobFileExtension(blobType) {
  try {
    if (!blobType) return "";
    // Split the type string by "/"
    const typeParts = blobType.split("/");
    // The last part should be the file extension
    return typeParts[typeParts.length - 1];
  } catch (error) {
    console.error(error);
  }
}
*/

/**
 * Download Email File ( with file attached - blob)
 * @param {Blob} blob
 * @param {String} fileName
 */
function downloadEMLFile(blob, fileName) {
  try {
    const blobType = blob?.type ?? "";
    // Create a new EML file content
    const boundary = "----=_NextPart_000_001A_01DA7B03.A146C8E0";

    let emlBuilder = "";
    emlBuilder += `MIME-Version: 1.0\r\n`;
    emlBuilder += `Content-Type: multipart/mixed; boundary="${boundary}"\r\n`;
    emlBuilder += `X-Unsent: 1\r\n`;
    emlBuilder += `Content-Language: en-us\r\n\r\n`;
    emlBuilder += `This is a multipart message in MIME format.\r\n\r\n`;

    emlBuilder += `--${boundary}\r\n`;
    emlBuilder += `Content-Type: text/plain; charset="us-ascii"\r\n`;
    emlBuilder += `Content-Transfer-Encoding: 7bit\r\n\r\n`;

    emlBuilder += `--${boundary}\r\n`;
    emlBuilder += `Content-Type: ${blobType}; name="${fileName}"\r\n`;
    emlBuilder += `Content-Transfer-Encoding: base64\r\n`;
    emlBuilder += `Content-Disposition: attachment; filename="${fileName}"\r\n\r\n`;

    // Read the content of the Blob as base64 string
    const reader = new FileReader();
    reader.onload = function(event) {
      try {
        const blobData = event.target.result.split(",")[1];
        emlBuilder += `${blobData}\r\n\r\n`;

        emlBuilder += `--${boundary}--\r\n`;

        const emlBlob = new Blob([emlBuilder], { type: "message/rfc822" });

        // Create a download link and trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(emlBlob);
        downloadLink.download = `${fileName}.eml`;
        downloadLink.click();
      } catch (error) {
        console.error("Error creating Blob or triggering download:", error);
      }
    };

    // Read the Blob content as data URL
    reader.readAsDataURL(blob);
  } catch (error) {
    console.error("Error in downloadEMLFile:", error);
  }
}

/**
 * Download Batch EML File (containing multiple attachments -blobs)
 * @param {{blob:Blob, fileName: string}[]} blobs
 */
function downloadBatchEMLFile(blobs) {
  try {
    const boundary = "----=_NextPart_000_001A_01DA7B03.A146C8E0";
    let emlBuilder = "";
    emlBuilder += `MIME-Version: 1.0\r\n`;
    emlBuilder += `Content-Type: multipart/mixed; boundary="${boundary}"\r\n`;
    emlBuilder += `X-Unsent: 1\r\n`;
    emlBuilder += `Content-Language: en-us\r\n\r\n`;
    emlBuilder += `This is a multipart message in MIME format.\r\n\r\n`;

    emlBuilder += `--${boundary}\r\n`;
    emlBuilder += `Content-Type: text/plain; charset="us-ascii"\r\n`;
    emlBuilder += `Content-Transfer-Encoding: 7bit\r\n\r\n`;

    let attachmentsProcessed = 0;

    const processAttachment = (blob, fileName) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        try {
          const blobData = event.target.result.split(",")[1];

          emlBuilder += `--${boundary}\r\n`;
          emlBuilder += `Content-Type: ${blob.type}; name="${fileName}"\r\n`;
          emlBuilder += `Content-Transfer-Encoding: base64\r\n`;
          emlBuilder += `Content-Disposition: attachment; filename="${fileName}"\r\n\r\n`;

          emlBuilder += `${blobData}\r\n\r\n`;

          attachmentsProcessed++;
          if (attachmentsProcessed === blobs.length) {
            emlBuilder += `--${boundary}--\r\n`;

            const emlBlob = new Blob([emlBuilder], { type: "message/rfc822" });

            // Create a download link and trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(emlBlob);
            downloadLink.download = `files.eml`;
            downloadLink.click();
          }
        } catch (error) {
          console.error("Error processing attachment:", error);
        }
      };
      reader.readAsDataURL(blob);
    };

    // Process each blob
    blobs.forEach(blob => {
      processAttachment(blob?.blob, blob?.fileName);
    });
  } catch (error) {
    console.error("Error in downloadEMLFile:", error);
  }
}

/**
 * Create Print Ready File Anchor
 * @param {Blob} blob
 */
function createPrintFileAnchor(blob) {
  const element = document.createElement("a");
  element.href = window.URL.createObjectURL(blob);

  // ready for printing
  // loads the specified resource into the new or existing browsing context
  // (window, <iframe> or tab) with the specified name
  //Instead of using { window.open(element.href) }, save a click of user and directly open print model

  const iframe = document.createElement("iframe"); //load content in an iframe to print later
  document.body.appendChild(iframe);

  iframe.style.display = "none";
  iframe.src = element.href;
  iframe.onload = function() {
    setTimeout(function() {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
}

export {
  createScriptElement,
  createCSSElement,
  createDownloadFileAnchor,
  createPrintFileAnchor,
  downloadEMLFile,
  downloadBatchEMLFile
};
