import { extensionName } from "@/model/record/fileModel";
import { browserName } from "@/services/dom/windowService";
import {
  createDownloadFileAnchor,
  downloadBatchEMLFile,
  downloadEMLFile
} from "@/services/dom/domService";
import { actionResultType } from "@/model/action/actionModel";

/**
 * show Save File Picker
 * @param {String|string} fileName
 * @param {String|string} extension
 * @param {Boolean|boolean} excludeAcceptAllOption
 * @param {Boolean|boolean} multiple
 * @param {Boolean|boolean} isSignedFile
 * @return {Promise<{FileSystemFileHandle}>}
 */
async function showSaveFilePicker(
  fileName,
  extension,
  isSignedFile = false,
  excludeAcceptAllOption = false,
  multiple = false
) {
  console.log(`showSaveFilePicker() extension:`, extension);

  const options = {
    suggestedName: fileName,
    id: isSignedFile ? "saveSignedFile" : undefined,
    types: [
      {
        description: extension,
        accept: {
          "text/markdown": ["." + extension]
        }
      }
    ],
    excludeAcceptAllOption: excludeAcceptAllOption,
    multiple: multiple
  };

  return await window.showSaveFilePicker(options);
}

/**
 * show Open File Picker
 * @param {String|string} extension
 * @param {Boolean|boolean} excludeAcceptAllOption
 * @param {Boolean|boolean} multiple
 * @param {Boolean|boolean} isSignedFile
 * @return {Promise<{FileSystemFileHandle}>}
 */
async function showOpenFilePicker(
  extension,
  isSignedFile = false,
  excludeAcceptAllOption = false,
  multiple = false
) {
  console.log(`showOpenFilePicker() extension:`, extension);

  const options = {
    id: isSignedFile ? "saveSignedFile" : undefined,
    types: [
      {
        description: extension,
        accept: {
          "text/markdown": ["." + extension]
        }
      }
    ],
    excludeAcceptAllOption: excludeAcceptAllOption,
    multiple: multiple
  };

  return await window.showOpenFilePicker(options);
}

/**
 * Write File
 * @param {{FileSystemFileHandle}} fileHandle
 * @param {any} contents
 * @return {Promise<void>}
 */
async function writeFile(fileHandle, contents) {
  // Create a FileSystemWritableFileStream to write to.
  const writable = await fileHandle.createWritable();

  // Write the contents of the file to the stream.
  await writable.write(contents);

  // Close the file and write the contents to disk.
  await writable.close();
}

/**
 * Verify Permission
 * @param {{FileSystemFileHandle}} fileHandle
 * @param {Boolean|boolean} withWrite
 * @return {Promise<boolean>}
 */
async function verifyPermission(fileHandle, withWrite) {
  const opts = { mode: permissionStatus.read };
  if (withWrite) {
    opts.mode = permissionStatus.readwrite;
  }

  // Check if we already have permission, if so, return true.
  if (
    (await fileHandle.queryPermission(opts)) === queryPermissionType.granted
  ) {
    return true;
  }

  // Request permission to the file, if the user grants permission, return true.
  if (
    (await fileHandle.requestPermission(opts)) === queryPermissionType.granted
  ) {
    return true;
  }

  // The user did not grant permission, return false.
  return false;
}

/**
 * Get Local File Contents
 * @param {acceptExtension : String} acceptExtension
 * @param {contentType : Number} contentType
 * @return {Promise<*>}
 */
async function getFileContents(
  acceptExtension = ".txt",
  contentType = readType.readAsText
) {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.accept = acceptExtension;
    input.type = "file";

    input.onchange = e => {
      if (e?.target?.files?.length) {
        // getting a hold of the file reference
        let file = e.target.files[0];
        const maxAllowedSize = 5 * 1024 * 1024;
        if (file.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          throw "Maximum File Size (5MB) Crossed";
        }

        // setting up the reader
        const reader = new FileReader();
        contentType === readType.readAsText
          ? reader.readAsText(file) // this is reading as data text
          : reader.readAsDataURL(file); // this is reading as data Url

        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
          const content = readerEvent.target.result; // this is the content !
          contentType === readType.readAsText
            ? resolve(JSON.parse(content))
            : resolve(content);
        };
        reader.onerror = error => reject(error);
      } else {
        throw "No file selected.";
      }
    };
    input.click();
  });
}

/**
 * Save File to a local directory
 * @param {Blob} blob
 * @param {String} name
 * @param {String} extension
 * @param {Boolean} isSignedFile
 * @param {Boolean} isBatchDownload
 */
async function saveFile(
  blob,
  name,
  extension = extensionName.text,
  isSignedFile = false,
  isBatchDownload = false
) {
  let fileHandle = undefined;
  if (!isBatchDownload) {
    try {
      fileHandle = await showSaveFilePicker(name, extension, isSignedFile);
    } catch (e) {
      console.warn(`Couldn't show Save File Picker`, e.toString());
      // Handle AbortError, since it has been thrown if the user dismisses
      // the file picker without selecting or inputting a file,
      // or if the user agent deems any selected files too sensitive or dangerous.
      if (e?.name === exceptionNames.AbortError) {
        console.warn(`Operation Aborted`, e.toString());
        return actionResultType.abort;
      }
    }

    // write file/Create download File anchor
    if (fileHandle) {
      console.log(`writeFile() using fileHandle extension:`, extension);
      await writeFile(fileHandle, blob);
    } else {
      console.warn(
        `File System Access API ${
          supportsFileSystemAccess ? "is" : "is NOT"
        } supported by ${browserName}`
      );
      createDownloadFileAnchor(blob, name, extension);
    }
  } else {
    createDownloadFileAnchor(blob, name, extension);
  }
}

/**
 * Prepare eml file with blob attachment
 * @param {Blob} blob
 * @param {String} name
 */
async function emailFile(blob, name) {
  try {
    downloadEMLFile(blob, name);
  } catch (e) {
    console.error(e.toString());
  }
}

/**
 * Prepare eml files with blobs attachment - Batch
 * @param {{blob:Blob, fileName: string}[]} blobs
 */
async function emailFiles(blobs) {
  try {
    downloadBatchEMLFile(blobs);
  } catch (e) {
    console.error(e.toString());
  }
}

/**
 * Open File from a local directory
 * @param {String} extension
 * @param {Boolean} isSignedFile
 */
async function openFile(extension = extensionName.pdf, isSignedFile = false) {
  let fileHandle = undefined;
  try {
    [fileHandle] = await showOpenFilePicker(extension, isSignedFile);
  } catch (e) {
    console.warn(`Couldn't show Open File Picker`, e.toString());
    // Handle AbortError, since it has been thrown if the user dismisses
    // the file picker without selecting or inputting a file,
    // or if the user agent deems any selected files too sensitive or dangerous.
    if (e?.name === exceptionNames.AbortError) {
      console.warn(`Operation Aborted`, e.toString());
    }
  }

  // write file/Create download File anchor
  if (fileHandle) {
    console.log(`openFile() using fileHandle extension:`, extension);
    return await fileHandle.getFile();
  } else {
    console.warn(
      `File System Access API ${
        supportsFileSystemAccess ? "is" : "is NOT"
      } supported by ${browserName}`
    );
  }
}

/**
 * Determines whether File System Access API is supported by current browser
 * @return {boolean}
 */
const supportsFileSystemAccess =
  supportedByWindow("chooseFileSystemEntries") ||
  supportedByWindow("showOpenFilePicker");

function supportedByWindow(name) {
  return name in window;
}

/**
 * exception Names
 * @type {Readonly<{AbortError: string}>}
 */
const exceptionNames = Object.freeze({
  AbortError: "AbortError"
});

/**
 * file Handle Kind
 * @type {Readonly<{file: string, directory: string}>}
 */
const fileHandleKType = Object.freeze({
  file: "file",
  directory: "directory"
});

/**
 * query Permission Types
 * @type {Readonly<{granted: string}>}
 */
const queryPermissionType = Object.freeze({
  granted: "granted"
});

/**
 * permission Statuses
 * @type {Readonly<{read: string, readwrite: string}>}
 */
const permissionStatus = Object.freeze({
  read: "read",
  readwrite: "readwrite"
});

/**
 * File Reader Content Types
 * @type {Readonly<{readAsText: Number, readAsUrl: Number}>}
 */
const readType = Object.freeze({
  readAsText: 0,
  readAsUrl: 1
});

export {
  showSaveFilePicker,
  writeFile,
  verifyPermission,
  supportsFileSystemAccess,
  exceptionNames,
  fileHandleKType,
  queryPermissionType,
  permissionStatus,
  getFileContents,
  saveFile,
  readType,
  showOpenFilePicker,
  openFile,
  emailFile,
  emailFiles
};
